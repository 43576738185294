import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import useFormsSubscriptions from '@/stores/forms/subscriptions';
import { IBillingPortalModal } from '../components/types';
import { ILicense, Period } from '@/stores/forms/types';

@Component
export default class StripeLicense extends Vue {
  @Prop({ type: Object, required: true })
  public readonly license: ILicense;

  protected isStripe = false;

  protected currency = 'USD';

  protected period: Period = Period.Monthly;

  formsSubscriptions = useFormsSubscriptions();

  @Ref()
  protected billingPortal: IBillingPortalModal;

  async created() {
    await this.loadSubscriptionInfo();
  }

  protected async loadSubscriptionInfo() {
    if (!this.license.subscriptionRef?.startsWith('sub_')) {
      return;
    }
    const info = await this.formsSubscriptions.getSubscription(this.license.subscriptionRef);
    if (info) {
      this.currency = info.currency;
      this.period = info.period;
      this.isStripe = true;
    }
  }

  protected async openBillingPortal() {
    const portal = await this.formsSubscriptions.getBillingPortal(this.license.subscriptionRef);
    if (portal.isUrl) {
      window.open(portal.content, '_blank');
    } else {
      this.billingPortal.open(portal.content);
    }
  }
}
