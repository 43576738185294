
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class MessageModal extends Vue {
  @Prop({ type: String, required: true })
  public readonly title: string;

  @Prop({ type: String, required: true })
  public readonly name: string;

  @Prop({ type: String, default: '' })
  public readonly okText: string;

  @Prop({ type: String, default: 'Close' })
  public readonly cancelText: string;

  get modalStyles() {
    return {
      overflow: 'auto',
      'box-shadow': 'none',
    };
  }

  public open() {
    this.$modal.show(this.name);
  }

  public close() {
    this.$modal.hide(this.name);
  }
}
