
import { Component, Ref } from 'vue-property-decorator';
import { formatDate, getCurrecySymbol } from '@/utils/index.js';
import PlanSwitcher from '@/views/forms/components/plan-switcher.vue';
import { Plan, Period, SubscriptionStatus, IPublicLicense } from '@/stores/forms/types';
import PublicPlanItem from './public-plan-item.vue';
import StripeLicense from '../mixins/stripe-license';
import InvoiceSentModal from './modals/invoice-sent-modal.vue';
import PaymentFailedModal from './modals/payment-failed-modal.vue';
import PaymentSucceededModal from './modals/payment-succeeded-modal.vue';
import BillingPortalModal from './modals/billing-portal-modal.vue';
import SomethingWrongModal from './modals/something-wrong-modal.vue';
import RenewSubscriptionModal from './modals/renew-subscription-modal.vue';
import { IPrice, IProrationCache } from './types';

const CHECK_LICENSES_INTERVAL_TIME = 5000;
const CHECK_LICENSES_TIMEOUT_TIME = 40000;

@Component({
  components: {
    PlanSwitcher,
    PublicPlanItem,
    InvoiceSentModal,
    PaymentFailedModal,
    PaymentSucceededModal,
    BillingPortalModal,
    SomethingWrongModal,
    RenewSubscriptionModal,
  },
})
export default class PublicPlan extends StripeLicense {
  public declare license: IPublicLicense;

  private planType: Period = Period.Annual;

  private subscriptionPlan: Plan = Plan.Scooter;

  private selectedPlan: IPrice | null = null;

  private isPricesLoading = false;

  private isTotalPriceLoading = false;

  private totalPrice: string | null = null;

  private calculateAbortController: AbortController | null = null;

  private prices: IPrice[] = [];

  private prorationCache: IProrationCache = {};

  private checkLicensesInterval: number | null = null;

  private checkLicensesTimeout: number | null = null;

  private upgradingInProgres = false;

  @Ref()
  private invoiceSent: InvoiceSentModal;

  @Ref()
  private somethingWrong: SomethingWrongModal;

  @Ref()
  private modalRef: HTMLElement;

  @Ref()
  private paymentSucceeded: PaymentSucceededModal;

  @Ref()
  private paymentFailed: PaymentFailedModal;

  @Ref()
  private renewSubscription: RenewSubscriptionModal;

  get title() {
    return Plan[this.license.type];
  }

  get monthlyFormsPlanList() {
    return this.prices
      .filter((p) => p.period === Period.Monthly)
      .sort((p1, p2) => p1.plan - p2.plan);
  }

  get annualFormsPlanList() {
    return this.prices
      .filter((p) => p.period === Period.Annual)
      .sort((p1, p2) => p1.plan - p2.plan);
  }

  get isActivePlanTruckAnnual() {
    return this.license.type === Plan.Truck && this.period === Period.Annual;
  }

  get isUpgradeVisible() {
    return this.license.type === Plan.Scooter || (this.isStripe && !this.isActivePlanTruckAnnual);
  }

  get isSwitchVisible() {
    return this.period === 0 && this.license.type !== Plan.Truck;
  }

  protected async loadSubscriptionInfo() {
    if (!this.license.subscriptionRef?.startsWith('sub_')) {
      return;
    }
    const info = await this.formsSubscriptions.getPublicFormsSubscription(this.license.subscriptionRef);
    this.currency = info.currency;
    this.period = info.period;
    this.subscriptionPlan = info.plan;
    if (this.license.type !== this.subscriptionPlan) {
      this.upgradingInProgres = true;
    }
    this.isStripe = true;
  }

  private formatDate = formatDate;

  private async onUpgradeClick() {
    if (this.license.type === Plan.Scooter) {
      this.openStore();
      return;
    }

    if (this.upgradingInProgres) {
      this.paymentSucceeded.open();
      return;
    }

    const statusData = await this.formsSubscriptions.getStatus(this.license.subscriptionRef);
    if (statusData.status === SubscriptionStatus.Cancelled) {
      this.openStore();
      return;
    }
    if (statusData.status === SubscriptionStatus.Cancelling) {
      this.renewSubscription.open(new Date(statusData.cancelAt ?? ''));
      return;
    }
    if (statusData.status === SubscriptionStatus.Pending) {
      this.invoiceSent.open(statusData.email!);
      return;
    }
    if (statusData.status === SubscriptionStatus.Unknown) {
      this.somethingWrong.open();
      return;
    }
    if (this.prices.length) {
      this.$modal.show('public-plan-modal');
    } else {
      this.isPricesLoading = true;
      this.$modal.show('public-plan-modal');

      this.prices = await this.formsSubscriptions.getPrices({
        currency: this.currency,
        period: this.period,
        plan: this.license.type,
      });

      this.isPricesLoading = false;
      if (this.prices.length === 1) {
        this.selectPlan(this.prices[0]);
      }
    }
  }

  private closeModal() {
    this.$modal.hide('public-plan-modal');
  }

  private setMinHeight() {
    const minHeight = this.modalRef.offsetHeight;
    this.modalRef.setAttribute('style', `min-height: ${minHeight}px`);
  }

  private async selectPlan(value: IPrice) {
    this.calculateAbortController?.abort();
    this.selectedPlan = value;

    const prorateCache = this.prorationCache[this.selectedPlan.id];
    if (!!prorateCache && new Date().getTime() - prorateCache.prorationDate.getTime() < 600000) {
      // 10 min
      this.totalPrice = prorateCache.totalPrice;
      return;
    }

    this.isTotalPriceLoading = true;

    this.calculateAbortController = new AbortController();

    const prorate = await this.formsSubscriptions.getProratePrice({
      subscriptionRef: this.license.subscriptionRef,
      priceId: this.selectedPlan.id,
      signal: this.calculateAbortController.signal,
    });

    const currencySymbol = getCurrecySymbol(this.currency);
    this.totalPrice = `${currencySymbol}${(prorate.subtotal / 100).toFixed(2)}`;

    this.prorationCache[this.selectedPlan.id] = {
      totalPrice: this.totalPrice,
      prorationDate: new Date(prorate.prorationDate),
    };

    this.isTotalPriceLoading = false;
  }

  private async upgradeSubscription() {
    if (!this.selectedPlan) {
      return;
    }

    this.isPricesLoading = true;

    const result = await this.formsSubscriptions.upgradeSubscription({
      subscriptionRef: this.license.subscriptionRef,
      priceId: this.selectedPlan.id,
      prorationDate: this.prorationCache[this.selectedPlan.id].prorationDate,
    });

    switch (result.status) {
      case 0:
        this.checkLicensesInterval = setInterval(this.checkLicenses, CHECK_LICENSES_INTERVAL_TIME);
        this.checkLicensesTimeout = setTimeout(() => {
          this.closeModal();
          this.paymentSucceeded.open();
          clearInterval(this.checkLicensesInterval ?? undefined);
          this.prorationCache = {};
          this.isPricesLoading = false;
          this.selectedPlan = null;
          this.upgradingInProgres = true;
        }, CHECK_LICENSES_TIMEOUT_TIME);
        return;
      case 1:
        this.closeModal();
        this.invoiceSent.open(result.email);
        break;
      default:
        this.closeModal();
        this.paymentFailed.open();
        break;
    }
    this.prorationCache = {};
    this.selectedPlan = null;
    this.isPricesLoading = false;
  }

  private async checkLicenses() {
    const [license, customer] = await Promise.all([
      this.formsSubscriptions.getLicenses(),
      this.formsSubscriptions.getSubscription(this.license.subscriptionRef),
    ]);
    if (
      license.publicLicense.type === this.selectedPlan?.plan &&
      customer.period === this.selectedPlan?.period
    ) {
      this.$emit('update:license', license.publicLicense);
      this.period = customer.period;
      this.prices = [];
      this.prorationCache = {};
      this.selectedPlan = null;
      this.closeModal();
      clearInterval(this.checkLicensesInterval ?? undefined);
      clearTimeout(this.checkLicensesTimeout ?? undefined);
      this.isPricesLoading = false;
    }
  }

  private openStore() {
    window.open('https://plumsail.com/forms/store/public-forms/', '_blank');
  }
}
