
import { Component } from 'vue-property-decorator';

import { formatDate } from '@/utils/index.js';
import StripeLicense from '../mixins/stripe-license';
import BillingPortalModal from './modals/billing-portal-modal.vue';
import { ISharePointLicense, SharePointPlan } from '@/stores/forms/types';

@Component({
  components: {
    BillingPortalModal,
  },
})
export default class SPPlan extends StripeLicense {
  declare public license: ISharePointLicense;

  get title() {
    if (this.license.remainingDays < 0) {
      return 'Expired';
    }
    return SharePointPlan[this.license.type];
  }

  get titleClass() {
    if (this.title === 'Expired') {
      return 'has-text-danger';
    }
    return '';
  }

  get isExpirationDateShowed() {
    return (
      new Date(this.license.expirationDate).getFullYear() - new Date().getFullYear() < 100
      && this.license.remainingDays > 0
    );
  }

  get expirationDate() {
    return formatDate({ date: new Date(this.license.expirationDate), format: 'LL' });
  }
}
