
import {
  Vue, Component, Watch, Ref,
} from 'vue-property-decorator';
import { mapState } from 'pinia';
import { formatDate } from '@/utils/index.js';
import MessageModal from '@/views/forms/components/modals/message-modal.vue';
import { IPublicLicense, ISharePointLicense } from '@/stores/forms/types';
import useFormsSubscriptions from '@/stores/forms/subscriptions';
import PublicPlan from './components/public-plan.vue';
import SpPlan from './components/sp-plan.vue';
import { useSettingsStore, useTeamsStore } from '@/stores/index.js';
import { apiEndpoint } from '@/utils/url-manager.js';

const CHECK_LICENSES_INTERVAL_TIME = 5000;
const ENABLE_CLEAR_BUTTON_TIMEOUT_TIME = 30000;

@Component({
  components: {
    SpPlan,
    PublicPlan,
    MessageModal,
  },
  computed: {
    ...mapState(useTeamsStore, ['getSelectedTeam']),
    ...mapState(useSettingsStore, ['getProfile']),
  },
})
export default class Subscriptions extends Vue {
  private license: IPublicLicense | null = null;

  private splicenses: ISharePointLicense[] | null = null;

  private showAddTenantSection = false;

  private enableStorageClearButton = true;

  private domain: string | null = null;

  private grantPermissions = true;

  private showInvalidDomainError = false;

  private showGettingStateError = false;

  private refreshLicenseStatusIntervalId: number | null = null;

  private enableCleanButtonTimeoutId: number | null = null;

  private formsSubscriptions = useFormsSubscriptions();

  private readonly getProfile: any;

  private readonly getSelectedTeam: any;

  @Ref()
  private readonly spDomainInput: HTMLElement;

  @Ref()
  private readonly cleanStorageModal: MessageModal;

  get lastStorageCleanKey() {
    return `forms_last_storage_clean_${this.getProfile.sub}`;
  }

  get usedSubmissions() {
    if (!this.license) {
      return 0;
    }
    return Math.max(this.license.plan.submissions - this.license.submissions, 0);
  }

  get usedStorage() {
    if (!this.license) {
      return 0;
    }
    return Math.max(
      this.license.plan.storage + (this.license.extraStorage ?? 0) - this.license.storage,
      0,
    );
  }

  get isOwnTeam() {
    return this.getSelectedTeam?.ownerId === this.getProfile.sub;
  }

  @Watch('isOwnTeam')
  private isOwnTeamChanged(newVal: boolean, oldVal: boolean) {
    if (!oldVal && newVal) {
      this.init();
    }
  }

  mounted() {
    if (!this.isOwnTeam) {
      return;
    }
    this.init();
  }

  private formatDate = formatDate;

  private init() {
    this.loadLicenses();

    const lastStorageClean = Number(window.localStorage.getItem(this.lastStorageCleanKey));
    if (lastStorageClean) {
      const time = Date.now() - lastStorageClean;
      if (time < ENABLE_CLEAR_BUTTON_TIMEOUT_TIME) {
        this.enableStorageClearButton = false;
        this.refreshLicenseStatusIntervalId = setInterval(
          this.checkClean,
          CHECK_LICENSES_INTERVAL_TIME,
        );
        this.enableCleanButtonTimeoutId = setTimeout(
          this.enableCleanButton,
          ENABLE_CLEAR_BUTTON_TIMEOUT_TIME - time,
        );
      } else {
        window.localStorage.removeItem(this.lastStorageCleanKey);
      }
    }
  }

  private async loadLicenses() {
    const licenses = await this.formsSubscriptions.getLicenses();
    this.license = licenses.publicLicense || { type: -1 };
    this.splicenses = licenses.sharePointLicenses;
  }

  private openAddTenantSection() {
    this.showAddTenantSection = true;
    this.$nextTick(() => {
      this.spDomainInput.focus();
    });
  }

  private closeAddTenantSection() {
    this.showAddTenantSection = false;
  }

  private async addTenant() {
    this.showGettingStateError = false;
    this.showInvalidDomainError = false;

    const domain = (this.domain ?? '')
      .replace(/^https?:\/\//, '')
      .split('/')[0]
      .toLowerCase();

    if (!/^[a-z0-9_\\-]+\.sharepoint\.com$/.test(domain)) {
      this.showInvalidDomainError = true;
      return;
    }
    try {
      const state = await this.formsSubscriptions.getStateForDomain(domain);
      const authority = domain.replace('sharepoint.com', 'onmicrosoft.com');

      if (this.grantPermissions) {
        const appId = '5033a373-7501-4296-9f53-4b5964c69a0c';
        const redirectUri = `${apiEndpoint}forms/admin_consent`;
        const url = `https://login.microsoftonline.com/${authority}/adminconsent?client_id=${appId}&state=${state}&redirect_uri=${redirectUri}&prompt=admin_consent`;
        window.location.href = url;
      } else {
        const appId = '9f6b14e7-21a6-40ba-b5ac-0a5e6a6c63ac';
        const redirectUri = `${apiEndpoint}forms/user_consent`;
        const url = `https://login.microsoftonline.com/${authority}/oauth2/v2.0/authorize?client_id=${appId}&state=${state}&response_type=code&response_mode=query&scope=https://graph.microsoft.com/User.Read&redirect_uri=${redirectUri}`;
        window.location.href = url;
      }
    } catch {
      this.showGettingStateError = true;
    }
  }

  private openCleanStorageModal() {
    this.cleanStorageModal.open();
  }

  private async clearStorage() {
    this.cleanStorageModal.close();
    this.enableStorageClearButton = false;
    try {
      await this.formsSubscriptions.cleanStorage();
      window.localStorage.setItem(this.lastStorageCleanKey, new Date().getTime().toString());
      this.enableCleanButtonTimeoutId = setTimeout(
        this.enableCleanButton,
        ENABLE_CLEAR_BUTTON_TIMEOUT_TIME,
      );
      this.refreshLicenseStatusIntervalId = setInterval(
        this.checkClean,
        CHECK_LICENSES_INTERVAL_TIME,
      );
    } catch {
      this.enableStorageClearButton = true;
    }
  }

  private async checkClean() {
    const licenses = await this.formsSubscriptions.getLicenses();
    if (licenses.publicLicense.storage !== this.license?.storage) {
      this.license = licenses.publicLicense;
      clearTimeout(this.enableCleanButtonTimeoutId ?? undefined);
      this.enableCleanButton();
    }
  }

  private enableCleanButton() {
    clearInterval(this.refreshLicenseStatusIntervalId ?? undefined);
    this.enableStorageClearButton = true;
    window.localStorage.removeItem(this.lastStorageCleanKey);
  }

  private saveAlertsEnabled(value: boolean) {
    this.formsSubscriptions.saveAlertsEnabled(value);
  }

  private formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 bytes';
    const k = 1024;
    const sizes = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
  }
}
