
import { Vue, Component, VModel } from 'vue-property-decorator';
import { Period } from '@/stores/forms/types';

@Component
export default class PlanSwitcher extends Vue {
  @VModel({
    type: Number,
    required: true,
    validator: (val: number) => [Period.Annual, Period.Monthly].includes(val),
  })
  public valueSynced!: Period;

  get controlClass() {
    if (this.valueSynced === Period.Annual) {
      return 'button-annual_active';
    }
    return 'button-monthly_active';
  }

  private toggle() {
    this.valueSynced = this.valueSynced === Period.Monthly
      ? Period.Annual
      : Period.Monthly;
  }

  private onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.toggle();
    }
  }
}
