import { defineStore } from 'pinia';
import axios, { AxiosResponse } from 'axios';
import {
  IPublicLicense,
  ISharePointLicense,
  PaymentStatus,
  Period,
  Plan,
  SubscriptionStatus,
} from './types';
import { apiEndpoint, formsApiEndpoint } from '@/utils/url-manager.js';

interface ISubscription {
  currency: string;
  period: Period;
}

interface IPublicFormsSubscription extends ISubscription {
  plan: Plan;
}

interface IStatusInfo {
  status: SubscriptionStatus;
  cancelAt: string | null;
  email?: string;
}

interface IBillingPortal {
  isUrl: boolean;
  content: string;
}

interface IPrice {
  id: string;
  price: number;
  plan: Plan;
  period: Period;
}

interface IProratePreview {
  prorationDate: string;
  subtotal: number;
  tax: number;
}

interface IUpgradeInfo {
  status: PaymentStatus;
  email: string;
}

interface ILicenseResponse {
  publicLicense: IPublicLicense;
  sharePointLicenses: ISharePointLicense[];
}

const endpoint = apiEndpoint;
const formsEndpoint = formsApiEndpoint;

export default defineStore('formsSubscriptions', () => {
  async function getSubscription(subscriptionRef: string) {
    return (await axios.get<ISubscription>(`${endpoint}subscriptions/${subscriptionRef}`)).data;
  }

  async function getPublicFormsSubscription(subscriptionRef: string) {
    return (await axios.get<IPublicFormsSubscription>(`${endpoint}subscriptions/${subscriptionRef}/public-forms`)).data;
  }

  async function getStatus(subscriptionRef: string) {
    return (await axios.get<IStatusInfo>(`${endpoint}subscriptions/${subscriptionRef}/status`)).data;
  }

  async function getBillingPortal(subscriptionRef: string) {
    return (await axios.get<IBillingPortal>(`${endpoint}subscriptions/${subscriptionRef}/portal`)).data;
  }

  async function getPrices({ currency, period, plan }: {
    currency: string,
    period: Period,
    plan: Plan,
  }) {
    return (await axios.get<IPrice[]>(`${endpoint}products/public-forms?currency=${currency}&currentPeriod=${period}&currentPlan=${plan}`)).data;
  }

  async function getProratePrice({ subscriptionRef, priceId, signal }: {
    subscriptionRef: string,
    priceId: string,
    signal: AbortSignal,
  }) {
    return (await axios.get<IProratePreview>(`${endpoint}subscriptions/${subscriptionRef}/preview/${priceId}`, {
      signal,
    })).data;
  }

  async function upgradeSubscription({ subscriptionRef, priceId, prorationDate }: {
    subscriptionRef: string,
    priceId: string,
    prorationDate: Date,
  }) {
    return (await axios.post<unknown, AxiosResponse<IUpgradeInfo>>(`${endpoint}subscriptions/${subscriptionRef}/upgrade`, {
      priceId,
      prorationDate,
    })).data;
  }

  async function getLicenses(): Promise<ILicenseResponse> {
    const response = await axios.get<ILicenseResponse>(`${formsEndpoint}v2/user/licenses`);
    const { publicLicense } = response.data;
    if (publicLicense) {
      publicLicense.submissions = Math.max(publicLicense.submissions, 0);
      publicLicense.storage = Math.max(publicLicense.storage, 0);
    }

    const { sharePointLicenses } = response.data;
    sharePointLicenses.sort((a, b) => {
      if (a.remainingDays > 0 && b.remainingDays > 0) {
        return a.domain > b.domain ? 1 : -1;
      }

      return a.remainingDays > b.remainingDays ? -1 : 1;
    });

    return {
      publicLicense,
      sharePointLicenses,
    };
  }

  async function getStateForDomain(domain: string) {
    return (await axios
      .get<{ state: string }>(
        `${endpoint}forms/state?domain=${encodeURIComponent(
          domain,
        )}&source=${encodeURIComponent(window.location.href)}`,
      )).data.state;
  }

  async function cleanStorage() {
    axios.delete(`${formsEndpoint}storage/clear`);
  }

  function saveAlertsEnabled(value: boolean) {
    axios.patch(`${formsEndpoint}v2/user/license`, {
      alertEnabled: value,
    });
  }

  return {
    getSubscription,
    getPublicFormsSubscription,
    getStatus,
    getBillingPortal,
    getPrices,
    getProratePrice,
    upgradeSubscription,
    getLicenses,
    getStateForDomain,
    cleanStorage,
    saveAlertsEnabled,
  };
});
