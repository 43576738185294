
import { Vue, Component, Ref } from 'vue-property-decorator';
import MessageModal from './message-modal.vue';

@Component({
  components: {
    MessageModal,
  },
})
export default class InvoiceSentModal extends Vue {
  private email: string | null = null;

  @Ref()
  private readonly modal!: MessageModal;

  public open(email: string) {
    this.email = email;
    this.modal.open();
  }

  public close() {
    this.modal.close();
  }
}
