
import { Vue, Component, Ref } from 'vue-property-decorator';
import MessageModal from './message-modal.vue';

@Component({
  components: {
    MessageModal,
  },
})
export default class RenewSubscriptionModal extends Vue {
  @Ref()
  private readonly modal!: MessageModal;

  private cancelAt: Date | null = null;

  get dateString() {
    return this.cancelAt?.toLocaleDateString();
  }

  public open(cancelAt: Date) {
    this.cancelAt = cancelAt;
    this.modal.open();
  }

  public close() {
    this.modal.close();
  }
}
