
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getCurrecySymbol } from '@/utils/index.js';
import { Plan } from '@/stores/forms/types';
import { IPrice } from './types';

@Component
export default class PublicPlanItem extends Vue {
  @Prop({ type: Object, required: true })
  public readonly plan: IPrice

  @Prop({ type: Object, default: null })
  public readonly selectedPlan: IPrice | null;

  @Prop({ type: String, required: true })
  public readonly currency: string;

  get isSelected() {
    return this.selectedPlan?.id === this.plan.id;
  }

  get currencySign() {
    return getCurrecySymbol(this.currency);
  }

  public onPlanClick() {
    if (!this.isSelected) {
      this.$emit('selectPlan', this.plan);
    }
  }

  public getPlanName(type: Plan) {
    return Plan[type];
  }
}
