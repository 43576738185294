export enum Plan {
  Scooter,
  Pickup,
  Van,
  Truck,
  SharePointActive
}

export enum SharePointPlan {
  Trial = 4,
  Active = 5
}

export enum Period {
  Monthly,
  Annual
}

export enum SubscriptionStatus {
  Unknown = 0,
  Active,
  Pending,
  Cancelling,
  Cancelled
}

export enum PaymentStatus {
  Success,
  Awaiting,
  Error
}

export interface ILicense {
  subscriptionRef: string;
}

export interface IPlanSettings {
  submissions: number;
  storage: number;
  extraStorage?: number;
}

export interface IPublicLicense extends ILicense, IPlanSettings {
  type: Plan;
  plan: IPlanSettings
}

export interface ISharePointLicense extends ILicense {
  type: SharePointPlan;
  domain: string;
  expirationDate: number;
  remainingDays: number;
}
